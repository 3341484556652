import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const Title = ({ title, center = false }) => (
  <Fade bottom duration={1000} delay={300} distance="0px">
    <h2 className="section-title" style={{ textAlign: center ? 'center' : 'left' }}>
      {title}
    </h2>
  </Fade>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  center: PropTypes.bool,
};

export default Title;
